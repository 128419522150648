import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { ActivateElementModule } from '../../directives/activate-element/activate-tab-element.module';
import { ButtonModule } from '../button/button.module';
import { IconButtonModule } from '../icon-button/icon-button.module';
import { ColorThemeModule } from '../shared/color-theme/color-theme.module';
import { ModalSlotDirective } from './standardized-modal/modal-slot.directive';
import { StandardizedModalComponent } from './standardized-modal/standardized-modal.component';


@NgModule({
  declarations: [
    StandardizedModalComponent,
    ModalSlotDirective
  ],
  imports: [
    CommonModule,
    IconButtonModule,
    ButtonModule,
    ActivateElementModule,
    ColorThemeModule,
    I18nModule
  ],
  exports: [StandardizedModalComponent, ModalSlotDirective]
})
export class UtilModalModule { }
